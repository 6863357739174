<template>
  <!-- 申报材料 -->
  <div class="main-contain">
    <template v-if="datalist.length > 0">
      <!-- <div class="item" v-for="(item, index) in datalist" :key="index" @click="downLoad(item)">
        <el-image class="elimage" :src="icon1" />
        <div class="text">{{ item.name }}</div>
      </div>-->

      <el-table class="table" ref="singleTable" :data="datalist" highlight-current-row>
        <el-table-column label="文件名" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="item-left">
              <el-image class="imageicon" :src="file_icon(scope.row.ext)" fit="contain" />
              <span class="title">{{ scope.row.name }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="大小">
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">{{ scope.row.size }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="item-right">
              <div class="bt" @click="lookbt(scope.row)">查看</div>
            </div>
            <div class="item-right">
              <div class="bt" @click="downLoad(scope.row)">下载</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
          :current-page="queryParams.page" @current-change="pageChange"></el-pagination>
      </div>
    </template>
    <template v-else>
      <div class="is-empty" style="text-align: center;">
        <img :src="no_math" class="no_math" style="width: 70%;" />
        <div class="vr_project">
          <span>暂无申报材料</span>
        </div>
      </div>
    </template>
    <Customdialog ref="customdialog" class="customdialog" width="800px" type="form" title="预览" :showclose="true"
      @isDialogClose="close_dialog">
      <div class="dialogbody" slot="dialogbody">
        <template v-if="type == '视频'">
          <video class="video_view" ref="vueRef" width="100%" height="500" controls
            controlslist="nodownload noplaybackrate" disablepictureinpicture :src="videoviewurl" />
        </template>
        <template v-if="type == '图片'">
          <iframe class="iframe_cont" :src="imageviewurl" frameborder="0" ref="myiframe"></iframe>
        </template>
        <template v-if="type == '其他'">
          <iframe class="iframe_cont" :src="file_url" frameborder="0" ref="myiframe"></iframe>
        </template>
        <template v-if="type == 'PDF'">
          <iframe class="iframe_cont" :src="fileurl" frameborder="0" ref="myiframe"></iframe>
        </template>
        <div id="toolbar" :class="toolbar == false ? 'tool-box doc_1' : 'tool-box'" style="visibility: visible;">
          <div class="line-bar1"></div>
          <div class="full-box">
            <a class="full-btn1" @click="screen">
              <i class="el-icon-full-screen" style="font-size:17px"></i>
              <span style="margin-left:4px">全屏</span>
            </a>
          </div>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <el-button type="info" @click="close_down">关闭</el-button>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import Customdialog from "@/components/customdialog.vue"
import { getVRAttachments } from "@/api/vr"
import { Base64 } from 'js-base64'
import screenfull from 'screenfull'
const request_base_path = require('@/utils/base_url')
export default {
  name: "Courseintroduction",
  components: {
    Customdialog
  },
  data() {
    return {
      datalist: [],
      fileicon1: require("@/assets/fileicon/files.png"),
      fileicon2: require("@/assets/fileicon/icon-PDF.png"),
      fileicon3: require("@/assets/fileicon/icon-word.png"),
      fileicon4: require("@/assets/fileicon/icon-video.png"),
      fileicon5: require("@/assets/fileicon/icon-img.png"),
      fileicon6: require("@/assets/fileicon/icon-node.png"),
      fileicon7: require("@/assets/fileicon/icon-audio.png"),
      fileicon8: require("@/assets/fileicon/icon-chapter.png"),
      fileicon9: require("@/assets/fileicon/rar.png"),
      fileicon10: require("@/assets/fileicon/single.png"),
      fileicon11: require("@/assets/fileicon/txt.png"),
      fileicon12: require("@/assets/fileicon/xlsx.png"),
      fileicon13: require("@/assets/fileicon/zip.png"),
      no_math: require('@/assets/no_math1.png'),
      imageviewurlList: [], // 图片数据
      isImg: ["jpg", "png", "jpeg", "gif", "webp"],
      fileurl: "",
      file_url: "",
      type: "",
      videoviewurl: "",
      imageviewurl: "",
      toolbar: false,

      total: 0,
      queryParams: {
        page: 1,
        per_page: 10
      },
      filePreview: "", // 数据预览
      picturesPreview: "", // 多图预览
      fileTask: "", // 数据预埋
    }
  },
  created () {
    const systemConfig = sessionStorage.getItem("systemConfig");
    const systemInfo = systemConfig ? JSON.parse(systemConfig) : {};
    if (systemInfo.file_preview_url) {
      this.filePreview = systemInfo.file_preview_url + "/onlinePreview?url="; // 数据预览
      this.picturesPreview = systemInfo.file_preview_url + "/picturesPreview?urls="; // 多图预览
      this.fileTask = systemInfo.file_preview_url + "/addTask?url=" // 数据预埋
    } else {
      this.filePreview = request_base_path.pro_url + "/file-preview/onlinePreview?url=" // 数据预览
      this.picturesPreview = request_base_path.pro_url + "/file-preview/picturesPreview?urls=" // 多图预览
      this.fileTask = request_base_path.pro_url + "/file-preview/addTask?url=" // 数据预埋
    }
  },
  methods: {
    initdata(data) {
      this.queryParams.vr_id = data.id
      this.queryParams.classify = 2
      this.getVRAttachments()
    },
    getVRAttachments() {
      getVRAttachments(this.queryParams)
        .then((res) => {
          this.datalist = res.data.data
          res.data.data.forEach(el => {
            if (this.isImg.includes(el.ext)) {
              this.imageviewurlList.push(el.url)
            }
            this.total = res.data.total
          })

        })
        .catch((error) => {
          //console.log(error)
        })
    },
    //页码发生改变时
    pageChange(val) {
      this.queryParams.page = val
      this.getVRAttachments()
    },
    file_icon(val) {
      switch (val) {
        case "file":
          return this.fileicon1

        case "pdf":
          return this.fileicon2

        case ("word", "docx"):
          return this.fileicon3

        case "mp4":
          return this.fileicon4

        case ("png", "jpg"):
          return this.fileicon5

        case "radio":
          return this.fileicon7

        case "rar":
          return this.fileicon9

        case "txt":
          return this.fileicon11

        case "xlsx":
          return this.fileicon12

        case "zip":
          return this.fileicon13

        default:
          return this.fileicon10
      }
    },
    async lookbt(item) {
      this.resetFill()
      await this.getTask(item.url)
      if (item.ext == 'mp4' || item.ext == 'avi' || item.ext == 'flv') {
        this.type = "视频"
        this.videoviewurl = item.url
        this.toolbar = false
      } else if (this.isImg.includes(item.ext)) {
        this.type = "图片"
        // this.imageviewurl = item.url
        let len = this.imageviewurlList.length
        for (let i = 0; i < this.imageviewurlList.length; i++) {
          if (this.imageviewurlList[i] == item.url) {
            let newArr = this.imageviewurlList.splice(i, len - i)
            let oldArr = this.imageviewurlList
            this.imageviewurlList = [...newArr, ...oldArr]
            break
          }
        }
        let url = this.imageviewurlList.join("|")
        this.imageviewurl = this.picturesPreview + encodeURIComponent(Base64.encode(url))
        this.toolbar = true
      } else if (item.ext == "pdf") {
        this.type = "PDF"
        this.fileurl = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      } else {
        this.type = "其他"
        this.file_url = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      }
      this.$refs.customdialog.dialogopenbt()
    },
    resetFill() {
      this.imageviewurl = ''
      this.fileurl = ''
      this.file_url = ''
    },
    getTask(data) {
      this.fileTask + data
    },
    downLoad(item) {
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = item.url
      link.setAttribute('download', item.name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    close_dialog() {
      this.resetFill()
    },
    close_down() {
      this.$refs.customdialog.dialogclosebt()
      let _vueRef = this.$refs.vueRef
      if (this.type === '视频') {
        _vueRef.pause() //暂停
      }
    },
    //全屏
    screen() {
      if (screenfull.isEnabled) {
        screenfull.toggle(this.$refs.myiframe)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;

  // .item {
  //   margin-top: 30px;
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: center;
  //   cursor: pointer;
  //   .elimage {
  //     margin-right: 10px;
  //     width: 16px;
  //     height: 20px;
  //   }
  //   .text {
  //     font-size: 15px;
  //     font-family: PingFang SC;
  //     font-weight: 400;
  //     color: #3d84ff;
  //   }
  // }

  .table {
    width: 100%;

    .item-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .imageicon {
        min-width: 25px;
        height: 25px;
      }

      .title {
        display: inline-block;
        margin-left: 4px;
        font-size: 15px;
        font-weight: 400;
        width: 200px;
        color: #222222;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }

    .item-middle {
      .title {
        font-size: 15px;

        font-weight: 400;
        color: #222222;
      }
    }

    .item-right {
      margin-right: 10px;

      .bt {
        font-size: 15px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
    }
  }

  .customdialog {
    .dialogbody {
      padding: 10px;

      .video_view {}

      .image_view {
        height: 600px;
        width: 100%;
      }

      .pdf_con {
        position: relative;
      }

      .tip_con {
        height: 60px;
        background: #e6f7ff;
        border: 1px solid #91d5ff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
          background: #1890ff;
          color: white;
        }

        .tip_tex {
          font-size: 20px;
          font-weight: bold;
          color: #999999;
        }
      }
    }

    .dialogfooter {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ::v-deep .cell {
    display: flex;
  }

  .elpagination {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }

  @media screen and (max-width: 640px) {
    .no_math {
      width: 100%;
    }
    .vr_project {
      font-size: 16px;
      font-weight: bold;
      color: #b5a2a2;
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1200px) {
     .no_math {
      width: 100%;
    }
    .vr_project {
      font-size: 16px;
      font-weight: bold;
      color: #b5a2a2;
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 1200px) {
    .vr_project {
      font-size: 24px;
      font-weight: bold;
      color: #b5a2a2;
      margin-bottom: 30px;
    }
  }
}
</style>
